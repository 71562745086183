// Misc functions
export const environment = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

export const getEnvironment = () => {
  const hostname = window && window.location && window.location.hostname;
  if (
    hostname === "whereisgeorge.app" ||
    hostname === "maps-app-konsf.ondigitalocean.app"
  ) {
    return environment.PRODUCTION;
  }
  return environment.DEVELOPMENT;
};
