import React, { useState, useEffect } from "react";
import { Row, Layout, Image } from "antd";
import { Navigate } from "react-router-dom";
import logo from "../assets/images/wheresgeorgelogo.svg";
import backgroundMap from "../assets/images/mapexample.png";
import routes from "../routes";

const { Header } = Layout;

const PublicLayout = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
    }
  }, [loggedIn]);
  return (
    <>
      {loggedIn && <Navigate to={routes.map} replace={true} />}
      <Layout
        style={{
          background: `url(${backgroundMap})`,
          width: "100%",
          minHeight: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
        }}
      >
        <Header
          style={{
            position: "fixed",
            height: 50,
            width: "100%",
            zIndex: 3,
            paddingLeft: 18,
            background: "transparent",
          }}
        >
          <Image
            width={240}
            src={logo}
            preview={false}
            style={{ margin: "8px 8px 20px 0px" }}
          />
        </Header>
        <Row
          justify="center"
          style={{
            paddingTop: 80,
            width: "100%",
            height: "100%",
          }}
        >
          {/* TODO george use once desktop design finished */}
          {/* for desktops */}
          {/* 
          <Col
            lg={12}
            md={0}
            sm={0}
            xs={0}
            style={{
              height: "100%",
              backgroundPosition: "right bottom",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Row type="flex" style={{ justifyContent: "flex-end" }}>
              <Col>
                <Row
                  type="flex"
                  style={{
                    justifyContent: "flex-start",
                    marginBottom: 24,
                    paddingLeft: 24,
                  }}
                >
                  <Image
                    width="80%"
                    src={landingPageImage}
                    style={{ borderRadius: 24 }}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            lg={12}
            md={0}
            sm={0}
            xs={0}
            style={{ paddingTop: 100, maxWidth: 600 }}
          >
            {props.children}
          </Col> */}

          {/* for small screens */}
          {/* <Col
            // lg={24}
            // md={24}
            // sm={24}
            // xs={24}
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              justifyContent: "center",
            }}
          > */}
          {props.children}
          {/* </Col> */}
        </Row>
      </Layout>
    </>
  );
};

export default PublicLayout;
