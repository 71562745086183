import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import friendsApi from "../../services/friendsApi";
import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import userApi from "../../services/userApi";
import useSupercluster from "use-supercluster";

export const useMap = () => {
  const mapRef = useRef();
  const [viewport, setViewport] = useState({
    width: "100vw",
    height: "100vh",
    zoom: 1,
  });

  const [markerData, setMarkerData] = useState([]);
  const [user, setUser] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [points, setPoints] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewport.zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const fetchFriendDestinations = async (userId) => {
    try {
      const response = await friendsApi.fetchFriendDestinations(userId);
      setMarkerData(response);
    } catch (error) {
      message.error(
        `Fetching friends failed: ${error || "Connection refused"}`
      );
      Sentry.captureException(error);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await userApi.fetchCurrentUser();
      const currentDestination = response.destinations[0];
      setUserLocation(currentDestination);
      setUser(response);
      setViewport((old) => ({
        ...old,
        latitude: currentDestination.latitude,
        longitude: currentDestination.longitude,
      }));
      return response;
    } catch (error) {
      message.error(`Fetching user failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    mixpanel.track("Map - view");
    // TODO george add info to redux upon sign in / initial load
    const user = fetchUser();
    if (user) {
      fetchFriendDestinations(user.id);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (markerData && user) {
      const points = markerData.map((marker) => ({
        type: "Feature",
        properties: {
          cluster: false,
          id: marker?.id,
          profile_image: marker?.friend?.profile_image,
          full_name: marker?.friend?.full_name,
          username: marker?.friend?.username,
          contact_email: marker?.friend?.contact_email,
          contact_instagram: marker?.friend?.contact_instagram,
          contact_mobile: marker?.friend?.contact_mobile,
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(marker?.friend?.destinations[0]?.longitude),
            parseFloat(marker?.friend?.destinations[0]?.latitude),
          ],
        },
      }));
      const userPoint = {
        type: "Feature",
        properties: {
          cluster: false,
          id: user?.id,
          profile_image: user?.profile_image,
          full_name: user?.full_name,
          username: user?.username,
          contact_email: user?.contact_email,
          contact_instagram: user?.contact_instagram,
          contact_mobile: user?.contact_mobile,
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(user?.destinations[0]?.longitude),
            parseFloat(user?.destinations[0]?.latitude),
          ],
        },
      };
      points.push(userPoint);
      setPoints(points);
    }
  }, [markerData, user]);

  return {
    mapRef,
    markerData,
    user,
    userLocation,
    isLoading,
    points,
    setIsLoading,
    setPoints,
    viewport,
    setViewport,
    bounds,
    clusters,
    supercluster,
    selectedMarker,
    setSelectedMarker,
    fetchFriendDestinations,
    fetchUser,
  };
};
