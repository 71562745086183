/* eslint-disable global-require */
import React from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import routes from "./routes";

import AuthLayout from "./layouts/AuthLayout";
import PublicLayout from "./layouts/PublicLayout";

// Pages

//  TODO george remove code relating to pages
// import LandingPage from "./pages/LandingPage/InfoView";
// import DownloadPage from "./pages/LandingPage/DownloadView";
import MapPage from "./pages/MapPage/index";
import RegisterPage from "./pages/RegisterPage/index";
import LoginPage from "./pages/LoginPage/index";
import YouPage from "./pages/YouPage/index";
import FriendPage from "./pages/FriendPage/index";

class App extends React.Component {
  componentDidMount() {
    require("./styles/index.css");
    require("./styles/override.css");
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <Routes>
        <Route
          exact
          path={routes.home}
          element={<Navigate to={routes.login} replace />}
        />
        <Route
          path={routes.map}
          element={<AuthLayout children={<MapPage />}></AuthLayout>}
        />
        <Route
          path={routes.friends}
          element={<AuthLayout children={<FriendPage />}></AuthLayout>}
        />
        <Route
          path={routes.you}
          element={<AuthLayout children={<YouPage />}></AuthLayout>}
        />
        <Route
          path={routes.register}
          element={<PublicLayout children={<RegisterPage />}></PublicLayout>}
        />
        <Route
          path={routes.login}
          element={<PublicLayout children={<LoginPage />}></PublicLayout>}
        />
        {/* TODO george remove code relating to page */}
        {/* <Route
          path={routes.landing}
          element={<PublicLayout children={<LandingPage />}></PublicLayout>}
        /> */}
        {/* TODO george remove code relating to page */}
        {/* <Route
          path={routes.download}
          element={<PublicLayout children={<DownloadPage />}></PublicLayout>}
        /> */}
        {/* TODO george fix redirect issue - if not url goes to white page? */}
        <Route index element={<Navigate to={routes.login} replace />} />
      </Routes>
    );
  }
}

export default App;
