import base from "./base";
import locationUtils from "../utils/locationUtils";
import { DESTINATIONS_ENDPOINT } from "../config";
import { destinationStatus } from "../constants";

const addDestination = async (userId, country, city = null) => {
  const location = city || country;
  const randomisedLatLong = locationUtils.randomiseLocation(
    location.latitude,
    location.longitude
  );
  const response = await base.api.post(`${DESTINATIONS_ENDPOINT}/`, {
    user: userId,
    country: country.id,
    city: city?.id,
    longitude: randomisedLatLong.longitude,
    latitude: randomisedLatLong.latitude,
    status: destinationStatus.CURRENT,
  });
  return response;
};

const updateCurrentDestination = async (destinationId) => {
  const response = await base.api.patch(
    `${DESTINATIONS_ENDPOINT}/${destinationId}/`,
    {
      status: destinationStatus.PAST,
    }
  );
  return response;
};

export default { addDestination, updateCurrentDestination };
