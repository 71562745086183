const routes = {
  home: "/",
  map: "/map",
  login: "/login",
  register: "/register",
  you: "/you",
  friends: "/friends",
};

export default routes;
