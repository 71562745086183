import base from "./base";
import { COUNTRIES_ENDPOINT } from "../config";

const fetchCountries = async () => {
  const response = await base.api.get(`${COUNTRIES_ENDPOINT}/`);
  return response;
};

const fetchCities = async (id, params) => {
  const response = await base.api.get(
    `${COUNTRIES_ENDPOINT}/${id}/cities/`,
    params
  );
  return response;
};

export default {
  fetchCountries,
  fetchCities,
};
