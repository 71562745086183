import React, { useState } from "react";
import * as Sentry from "@sentry/browser";
// TODO george add mixpanel events
import mixpanel from "mixpanel-browser";
import friendsApi from "../../services/friendsApi";
import {
  Row,
  Avatar,
  message,
  Tooltip,
  Skeleton,
  List,
  Empty,
  Typography,
  Button,
  Collapse,
  Image,
} from "antd";
import {
  UserDeleteOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { friendStatus } from "../../constants";

const { Text, Title } = Typography;
const { Panel } = Collapse;

const FriendsTab = ({
  acceptedFriends,
  blockedFriends,
  setSelectedItem,
  setShowBlockModal,
  setShowSendRequestModal,
  requestedFriends,
}) => {
  const [selectedFriendList, setSelectedFriendList] = useState(null);
  const [isFriendListLoading, setFriendListLoading] = useState(true);

  const selectItemOnClick = (item) => {
    setSelectedItem(item);
  };

  const showBlockModal = (boolean) => {
    setShowBlockModal(boolean);
  };

  const showSendRequestModal = (boolean) => {
    setShowSendRequestModal(boolean);
  };

  // TODO george move logic to BE
  const removeBlockedUsers = (friends) => {
    const updatedArray = friends.filter(
      (item) => !blockedFriends.includes(item.friend.id)
    );
    return updatedArray;
  };

  const selectedFriendUpdated = async (friendId) => {
    setFriendListLoading(true);
    if (friendId) {
      try {
        const response = await friendsApi.fetchFriends({
          friend_id: friendId,
          status: friendStatus.ACCEPTED,
        });
        const updatedArray = removeBlockedUsers(response);
        setSelectedFriendList(updatedArray);
      } catch (error) {
        message.error(`Finding user failed: ${error || "Connection refused"}`);
        Sentry.captureException(error);
        setFriendListLoading(false);
      }
    }
    if (!friendId) {
      setSelectedFriendList(null);
    }
    setFriendListLoading(false);
  };

  return (
    <>
      <Title level={5}>Friends</Title>
      {acceptedFriends.length === 0 ? (
        <Empty
          description="You should probably make some friends"
          style={{ marginTop: 16 }}
        />
      ) : (
        <Collapse
          ghost
          accordion
          onChange={(value) => selectedFriendUpdated(value)}
          style={{ alignContent: "middle", marginTop: 16 }}
        >
          {acceptedFriends.map((item) => (
            <Panel
              header={
                <Row
                  type="flex"
                  align="middle"
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <Image
                    alt={item.username}
                    preview={false}
                    height={40}
                    width={40}
                    style={{
                      borderRadius: "50%",
                      display: "block",
                      objectFit: "cover",
                    }}
                    src={item.profile_image}
                    placeholder={
                      <Avatar size="large" icon={<UserOutlined />} />
                    }
                    fallback={<UserOutlined />}
                  />
                  <Row>
                    <Text style={{ marginLeft: 12 }}>{item.full_name}</Text>
                  </Row>
                </Row>
              }
              key={item.id}
              extra={
                <Button
                  shape="circle"
                  icon={<UserDeleteOutlined />}
                  onClick={() => {
                    selectItemOnClick(item);
                    showBlockModal(true);
                  }}
                />
              }
            >
              {selectedFriendList && (
                <>
                  <Text>Friends of friend</Text>
                  <List
                    loading={isFriendListLoading}
                    itemLayout="horizontal"
                    size="large"
                    style={{ marginLeft: 16 }}
                    dataSource={selectedFriendList}
                    locale={{
                      emptyText: (
                        <Empty
                          description="No available friends"
                          style={{ marginTop: 16 }}
                        />
                      ),
                    }}
                    renderItem={(item) => (
                      <List.Item>
                        <Skeleton
                          avatar
                          title={false}
                          loading={item.loading}
                          active
                        >
                          <List.Item.Meta
                            avatar={
                              item.friend?.profile_image ? (
                                <Avatar
                                  src={item.friend.profile_image}
                                  size="large"
                                />
                              ) : (
                                <Avatar size="large" icon={<UserOutlined />} />
                              )
                            }
                            title={item.friend.full_name}
                            description={item.friend.username}
                          />
                          <Row style={{ margin: 8 }}>
                            {acceptedFriends.find(
                              (acceptedFriend) =>
                                acceptedFriend.id === item.friend.id
                            ) ||
                            requestedFriends.find(
                              (requestedFriend) =>
                                requestedFriend.id === item.friend.id
                            ) ? (
                              <Tooltip
                                placement="topRight"
                                title="You can not send duplicate friend requests"
                              >
                                <Button
                                  type="text"
                                  disabled
                                  icon={<SendOutlined />}
                                />
                              </Tooltip>
                            ) : (
                              <Button
                                type="text"
                                icon={<SendOutlined />}
                                onClick={() => {
                                  // Pass user item
                                  selectItemOnClick(item.friend);
                                  showSendRequestModal(true);
                                }}
                              />
                            )}
                          </Row>
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                </>
              )}
            </Panel>
          ))}
        </Collapse>
      )}
    </>
  );
};

export default FriendsTab;
