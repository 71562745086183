import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import {
  Card,
  Row,
  Avatar,
  message,
  Select,
  Button,
  Form,
  Input,
  Tooltip,
  Typography,
  Switch,
  Collapse,
} from "antd";
import { UserOutlined } from "@ant-design/icons";

import userApi from "../../services/userApi";
import locationsApi from "../../services/locationsApi";
import destinationApi from "../../services/destinationApi";

import routes from "../../routes";
import RecommendationModal from "../../components/RecommendationModal";

const { Option } = Select;
const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const YouPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  //   TODO george add to redux
  const [countries, setCountries] = useState(null);
  const [cities, setCities] = useState(null);
  const [useSharingQr, setUseSharingQr] = useState(true);
  const [usersForRecommendations, setUsersForRecommendations] = useState([]);
  const [updatedDestination, setUpdatedDestination] = useState(null);
  const [areRecommendedUsersFriends, setAreRecommendedUsersFriends] =
    useState(false);

  useEffect(() => {
    mixpanel.track("You - page view");
    // TODO george add info to redux upon sign in / initial load
    fetchUser().then((user) => {
      fetchCountries();
      fetchCities(user.destinations[0].country.id);
    });
    setIsLoading(false);
  }, []);

  const fetchUser = async () => {
    try {
      const response = await userApi.fetchCurrentUser();
      setUser(response);
      return response;
    } catch (error) {
      message.error(`Fetching user failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
      setIsLoading(false);
    }
  };

  // TODO george change to fetchCountries to match RegisterPage
  const fetchCountries = async () => {
    try {
      const response = await locationsApi.fetchCountries();
      setCountries(response);
    } catch (error) {
      message.error(
        `Fetching countries failed: ${error || "Connection refused"}`
      );
      Sentry.captureException(error);
      setIsLoading(false);
    }
  };

  const fetchCities = async (country) => {
    if (country) {
      const citiesResponse = await locationsApi.fetchCities(country);
      setCities(citiesResponse);
    } else {
      setCities(null);
    }
  };

  const updateDestination = async (country, userCountry, city, userCity) => {
    const countryObject = countries.find((item) => item.id === country);
    if (country !== userCountry) {
      if (!city) {
        await destinationApi.updateCurrentDestination(
          user?.destinations[0]?.id
        );
        await destinationApi.addDestination(user.id, countryObject);
      } else {
        const cityObject = cities.find((item) => item.id === city);
        await destinationApi.updateCurrentDestination(
          user?.destinations[0]?.id
        );
        await destinationApi.addDestination(user.id, countryObject, cityObject);
      }
    }
    if (country === userCountry && city !== userCity) {
      const cityObject = cities.find((item) => item.id === city);
      await destinationApi.updateCurrentDestination(user?.destinations[0]?.id);
      await destinationApi.addDestination(user.id, countryObject, cityObject);
    }
    setUpdatedDestination(countryObject.name);
    const userList = await userApi.fetchUsersForRecommendations();
    if (userList.length > 0) {
      setAreRecommendedUsersFriends(true);
      setAreRecommendedUsersFriends(userList.shift(0)["friends"]);
      setUsersForRecommendations(userList);
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const {
      username,
      email,
      fullName,
      contactEmail,
      contactInstagram,
      contactNumber,
      country,
      city,
    } = values;
    try {
      if (
        username !== user?.username ||
        email !== user?.email ||
        fullName !== user?.fullName ||
        contactEmail !== user?.contactEmail ||
        contactInstagram !== user?.contactInstagram ||
        contactNumber !== user?.contactNumber
      ) {
        await userApi.updateUser(
          user.id,
          username,
          email,
          fullName,
          contactEmail,
          contactInstagram,
          contactNumber
        );
      }
      if (
        country !== user?.destinations[0]?.country?.id ||
        city !== user?.destinations[0]?.city?.id
      ) {
        await updateDestination(
          country,
          user?.destinations[0]?.country?.id,
          city,
          user?.destinations[0]?.city?.id
        );
      }
      mixpanel.track("You - update destination", {
        country: country,
        city: city,
      });

      setIsLoading(false);
    } catch (error) {
      message.error(`Profile update failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
      setIsLoading(false);
    }
  };

  return (
    <div style={{ marginTop: 50 }}>
      <Form
        form={form}
        className="you-form"
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row style={{ margin: 24 }}>
          <Card
            style={{
              width: "100%",
            }}
            loading={!user || isLoading}
          >
            <Row type="flex" justify="center" style={{ marginBottom: 24 }}>
              {user?.profile_image ? (
                <Avatar size={140} src={user.profile_image} />
              ) : (
                <Avatar size={140} icon={<UserOutlined />} />
              )}
            </Row>
            <Form.Item
              name="fullName"
              label="Full name"
              tooltip="This is how users will recognise you outside of your username."
              initialValue={user?.full_name}
              rules={[
                {
                  required: true,
                  message: "Please input your name",
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="George Ballard" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              tooltip="This will only be used for login and password reset. We won't send you any nonsense."
              initialValue={user?.email}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid email.",
                },
                {
                  required: true,
                  message: "Please input your email.",
                },
              ]}
            >
              <Input placeholder="georgeballard@gmail.com" />
            </Form.Item>
            <Form.Item
              name="username"
              label="Username"
              placeholder="beyoncebooty"
              tooltip="This is how other users will find you. It is recommended that you reuse your instagram handle."
              initialValue={user?.username}
              rules={[
                {
                  required: true,
                  message: "Please input your username",
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="beyoncebooty" />
            </Form.Item>
          </Card>
        </Row>

        <Row style={{ margin: 24 }}>
          <Card
            title={
              <Tooltip title="This is how friends will contact you.">
                How will friends contact you
              </Tooltip>
            }
            style={{
              width: "100%",
            }}
            loading={!user || isLoading}
          >
            <Form.Item
              name="contactEmail"
              label="Email"
              initialValue={user?.contact_email}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid email.",
                },
              ]}
            >
              <Input placeholder="georgeballard@gmail.com" />
            </Form.Item>
            <Form.Item
              name="contactInstagram"
              label="Instagram"
              placeholder="beyoncebooty"
              initialValue={user?.contact_instagram}
            >
              <Input placeholder="beyoncebooty" />
            </Form.Item>
            <Form.Item
              name="contactNumber"
              label="Phone number (WhatsApp/Signal etc)"
              tooltip="Add the country code to ensure that you appear correctly in WhatsApp, Signal etc."
              initialValue={user?.contact_mobile}
            >
              <Input placeholder="+447804233529" />
            </Form.Item>
          </Card>
        </Row>
        {user?.destinations[0]?.id && (
          <Row style={{ margin: 24 }}>
            <Card
              title="Current location"
              style={{
                width: "100%",
              }}
              loading={!user || isLoading}
            >
              <Form.Item
                name="country"
                label="Country"
                initialValue={user?.destinations[0]?.country.id}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Search for a country"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(value) => {
                    form.setFieldsValue({ city: null });
                    fetchCities(value);
                  }}
                >
                  {countries &&
                    countries.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="city"
                label="City (Optional)"
                initialValue={user?.destinations[0]?.city?.id}
              >
                <Select
                  placeholder="Search for a city"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(value) => {
                    if (value) {
                      form.setFieldsValue({ city: value });
                    } else {
                      form.setFieldsValue({ city: null });
                    }
                  }}
                >
                  {cities &&
                    cities.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Card>
          </Row>
        )}
        <Row style={{ margin: 24 }}>
          <Card
            title="Your unique links"
            style={{
              width: "100%",
            }}
          >
            <Collapse defaultActiveKey={["1"]} ghost>
              <Panel header="Registration link" key="1">
                <Row>
                  <Text>
                    If your friend doesn't have the the app, then share the link
                    below with your friend, and you will be sent a friend
                    request from them as part of their registration:
                  </Text>
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                    marginTop: 8,
                  }}
                >
                  <Switch
                    checkedChildren="In person"
                    unCheckedChildren="Via message"
                    checked={useSharingQr}
                    onChange={(value) => setUseSharingQr(value)}
                  />
                </Row>

                <Row
                  style={{
                    justifyContent: "center",
                    marginTop: 8,
                  }}
                >
                  {useSharingQr ? (
                    <QRCode
                      size={100}
                      value={`${window.location.origin}/register?registration_code=${user?.uuid}`}
                    />
                  ) : (
                    <Paragraph
                      copyable
                      type="secondary"
                    >{`${window.location.origin}/register?registration_code=${user?.uuid}`}</Paragraph>
                  )}
                </Row>
              </Panel>
              <Panel header="Add friend link" key="2">
                <Row>
                  <Text>
                    If you friend already has the app, then share the link below
                    with your friends, and you will be sent a friend request
                    from them:
                  </Text>
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                    marginTop: 8,
                  }}
                >
                  <Switch
                    checkedChildren="In person"
                    unCheckedChildren="Via message"
                    checked={useSharingQr}
                    onChange={(value) => setUseSharingQr(value)}
                  />
                </Row>
                <Row
                  style={{
                    justifyContent: "center",
                    marginTop: 8,
                  }}
                >
                  {useSharingQr ? (
                    <QRCode
                      size={100}
                      value={`${window.location.origin}/friends?add_friend_code=${user?.uuid}`}
                    />
                  ) : (
                    <Paragraph
                      copyable
                      type="secondary"
                    >{`${window.location.origin}/friends?add_friend_code=${user?.uuid}`}</Paragraph>
                  )}
                </Row>
              </Panel>
            </Collapse>
          </Card>
        </Row>
        <Row type="flex" justify="space-around" style={{ marginBottom: 24 }}>
          <Form.Item {...tailFormItemLayout}>
            <Button htmlType="submit" loading={!user || isLoading}>
              Update
            </Button>
          </Form.Item>
          <Button
            style={{ ...tailFormItemLayout }}
            loading={isLoading}
            onClick={() => {
              localStorage.removeItem("token");
              navigate(routes.login);
            }}
          >
            Log out
          </Button>
        </Row>
      </Form>
      {usersForRecommendations.length > 0 && (
        <RecommendationModal
          userList={usersForRecommendations}
          friends={areRecommendedUsersFriends}
          countryName={updatedDestination}
          loading={isLoading}
          visible={usersForRecommendations.length > 0}
          onOk={() => {
            setUsersForRecommendations([]);
            setAreRecommendedUsersFriends(false);
          }}
          onCancel={() => {
            setUsersForRecommendations([]);
            setAreRecommendedUsersFriends(false);
          }}
        />
      )}
    </div>
  );
};

export default YouPage;
