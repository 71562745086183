import randomLocation from "random-location";

const DISTANCE = 6000;

const randomiseLocation = (latitude, longitude) => {
  const location = {
    latitude,
    longitude,
  };
  const randomPoint = randomLocation.randomCirclePoint(location, DISTANCE);
  return randomPoint;
};

const randomiseLocationFromObject = (locationObject) => {
  const latitude = locationObject.latitude;
  const longitude = locationObject.longitude;
  const location = {
    latitude,
    longitude,
  };
  const randomPoint = randomLocation.randomCirclePoint(location, DISTANCE);
  locationObject.longitude = randomPoint.longitude;
  locationObject.latitude = randomPoint.latitude;

  return locationObject;
};

export default {
  randomiseLocation,
  randomiseLocationFromObject,
};
