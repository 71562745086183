import base from "./base";
import { BASE_API_ENDPOINT, USER_ENDPOINT } from "../config";

const fetchCurrentUser = async () => {
  const response = await base.api.get(`${USER_ENDPOINT}/`);
  return response;
};

const updateUser = async (
  userId,
  username,
  email,
  fullName,
  contactEmail,
  contactInstagram,
  contactNumber
) => {
  const response = await base.api.put(`${USER_ENDPOINT}/${userId}/`, {
    username,
    email,
    full_name: fullName,
    contact_email: contactEmail,
    contact_instagram: contactInstagram,
    contact_mobile: contactNumber,
  });
  return response;
};

const findFriend = async (search) => {
  const response = await base.api.get(
    `${USER_ENDPOINT}/find-friend/?search=${search}`
  );
  return response;
};

const findFriendFromCode = async (code) => {
  const response = await base.api.get(
    `${USER_ENDPOINT}/find-friend-from-code/?code=${code}`
  );
  return response;
};

const fetchSuggested = async () => {
  const response = await base.api.get(
    `${USER_ENDPOINT}/fetch-suggested-friends/`
  );
  return response;
};

const fetchUsersForRecommendations = async () => {
  const response = await base.api.get(
    `${USER_ENDPOINT}/fetch-users-for-recommendations/`
  );
  return response;
};

export const createUserDevice = async (user, registration_id) => {
  return await base.api.post(
    `${BASE_API_ENDPOINT}/user-device/`,
    { user: user.id, registration_id: registration_id, type: "web" },
    { headers: { "Content-Type": "application/json" } }
  );
};

const uploadProfileImage = async (userId, image) => {
  const formData = new FormData();
  formData.append("image", image);
  const response = await base.api.put(
    `${USER_ENDPOINT}/${userId}/upload-profile-image/`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response;
};

export default {
  fetchCurrentUser,
  updateUser,
  findFriend,
  uploadProfileImage,
  findFriendFromCode,
  fetchSuggested,
  fetchUsersForRecommendations,
};
