import React, { useMemo } from "react";
import mixpanel from "mixpanel-browser";
import { MAPBOX_API_TOKEN, MAPBOX_MAP_STYLE } from "../../config";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
import { Avatar, Col, Row, Typography, Image } from "antd";
import {
  GoogleOutlined,
  InstagramOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { useMap } from "./useMap";
import "./style.css";

const { Paragraph } = Typography;
const Map = () => {
  const {
    points,
    isLoading,
    viewport,
    setViewport,
    mapRef,
    clusters,
    supercluster,
    setSelectedMarker,
    selectedMarker,
  } = useMap();

  const popUp = useMemo(() => {
    if (selectedMarker) {
      const currentDestination = selectedMarker.geometry.coordinates;
      const {
        contact_email,
        contact_instagram,
        contact_mobile,
        full_name,
        profile_image,
        username,
      } = selectedMarker?.properties;
      return (
        <Popup
          longitude={currentDestination[0]}
          latitude={currentDestination[1]}
          anchor="top"
          onClose={() => setSelectedMarker(null)}
          closeButton
          closeOnClick={false}
        >
          <Row type="flex" align="middle">
            <Col>
              {profile_image ? (
                <Avatar
                  src={profile_image}
                  size="large"
                  style={{ marginBottom: 8 }}
                />
              ) : (
                <Avatar
                  size="large"
                  icon={<UserOutlined />}
                  style={{ marginBottom: 8 }}
                />
              )}
            </Col>
            <Col>
              <Row>
                <Paragraph
                  ellipsis={{ rows: 1 }}
                  style={{
                    marginBottom: 0,
                    marginLeft: 8,
                    marginRight: 8,
                    maxWidth: 120,
                  }}
                >
                  {full_name}
                </Paragraph>
              </Row>
              <Row>
                <Paragraph
                  // TODO george why does this cause premature ellipsis?
                  // copyable
                  ellipsis={{ rows: 1 }}
                  style={{
                    marginBottom: 0,
                    marginLeft: 8,
                    marginRight: 8,
                    maxWidth: 110,
                  }}
                >
                  {username}
                </Paragraph>
              </Row>
            </Col>
          </Row>
          {contact_email && (
            <Row type="flex" align="middle">
              <GoogleOutlined />
              {": "}
              <Paragraph
                copyable
                ellipsis={{ rows: 1 }}
                style={{ marginBottom: 0, marginLeft: 4, maxWidth: 160 }}
              >
                {contact_email}
              </Paragraph>
            </Row>
          )}
          {contact_instagram && (
            <Row type="flex" align="middle">
              <InstagramOutlined />
              {": "}
              <Paragraph
                copyable
                ellipsis={{ rows: 1 }}
                style={{ marginBottom: 0, marginLeft: 4, maxWidth: 160 }}
              >
                {contact_instagram}
              </Paragraph>
            </Row>
          )}
          {contact_mobile && (
            <Row type="flex" align="middle">
              <WhatsAppOutlined />
              {": "}
              <Paragraph copyable style={{ marginBottom: 0, marginLeft: 4 }}>
                {contact_mobile}
              </Paragraph>
            </Row>
          )}
        </Popup>
      );
    }
  }, [selectedMarker]);

  return (
    <ReactMapGL
      {...viewport}
      style={{ width: "100vw", height: "100vh" }}
      mapStyle={MAPBOX_MAP_STYLE}
      mapboxAccessToken={MAPBOX_API_TOKEN}
      onMove={(evt) => setViewport(evt.viewState)}
      reuseMaps
      maxPitch={0}
      dragRotate={false}
      touchZoomRotate={{ disableRotation: true }}
      attributionControl={false}
      maxZoom={20}
      onViewportChange={(newViewport) => {
        setViewport({ ...newViewport });
      }}
      ref={mapRef}
    >
      {clusters.map((cluster, index) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const {
          cluster: isCluster,
          point_count: pointCount,
          profile_image,
          username,
        } = cluster.properties;

        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              latitude={latitude}
              longitude={longitude}
            >
              <div
                className="cluster-marker"
                style={{
                  width: `${10 + (pointCount / points.length) * 20}px`,
                  height: `${10 + (pointCount / points.length) * 20}px`,
                }}
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  );

                  setViewport({
                    ...viewport,
                    latitude,
                    longitude,
                    zoom: expansionZoom,
                    transitionDuration: "auto",
                  });
                }}
              >
                {pointCount}
              </div>
            </Marker>
          );
        }

        return (
          <Marker
            key={`key-${index}`}
            latitude={latitude}
            longitude={longitude}
          >
            {/* TODO george center image */}
            {profile_image ? (
              <Image
                alt={username}
                preview={false}
                height={40}
                width={40}
                style={{
                  borderRadius: "50%",
                  display: "block",
                  objectFit: "cover",
                }}
                src={profile_image}
                placeholder={<Avatar size="large" icon={<UserOutlined />} />}
                fallback={<UserOutlined />}
                onClick={(e) => {
                  mixpanel.track("Map - click marker", {
                    marker_id: cluster.properties.id,
                  });
                  e.preventDefault();
                  setSelectedMarker(cluster);
                }}
              />
            ) : (
              <Avatar
                size="large"
                icon={<UserOutlined />}
                onClick={(e) => {
                  mixpanel.track("Map - click marker", {
                    marker_id: cluster.properties.id,
                  });
                  e.preventDefault();
                  setSelectedMarker(cluster);
                }}
              />
            )}
          </Marker>
        );
      })}
      {!isLoading && selectedMarker && popUp}
    </ReactMapGL>
  );
};

export default Map;
