import React from "react";
import * as Sentry from "@sentry/browser";
// TODO george add mixpanel events
import mixpanel from "mixpanel-browser";
import {
  Row,
  Avatar,
  Tooltip,
  Skeleton,
  List,
  Empty,
  Button,
  Input,
  Typography,
} from "antd";
import { SendOutlined, UserOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const { Search } = Input;

const AddFriendsTab = ({
  acceptedFriends,
  setSelectedItem,
  setShowSendRequestModal,
  requestedFriends,
  suggestedFriends,
  onSearchUpdated,
  searchResponse,
  isLoading,
}) => {
  const handleSearchUpdated = (value) => {
    onSearchUpdated(value);
  };

  const selectItemOnClick = (item) => {
    setSelectedItem(item);
  };

  const showSendRequestModal = (boolean) => {
    setShowSendRequestModal(boolean);
  };

  return (
    <>
      <Title level={5}>Add friends</Title>
      <Row style={{ marginBottom: 40 }}>
        <Search
          placeholder="Search by name, username, email etc"
          onSearch={(value) => handleSearchUpdated(value)}
          style={{
            width: "100%",
            marginBottom: 24,
          }}
          allowClear
          loading={isLoading}
        />
        {searchResponse && (
          <List
            loading={isLoading}
            itemLayout="horizontal"
            size="large"
            style={{ marginBottom: 24, width: "100%" }}
            dataSource={searchResponse}
            locale={{
              emptyText: (
                <Empty description="No users found" style={{ marginTop: 16 }} />
              ),
            }}
            renderItem={(item) => (
              <List.Item style={{ marginLeft: 16 }}>
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={
                      item.profile_image ? (
                        <Avatar src={item.profile_image} size="large" />
                      ) : (
                        <Avatar size="large" icon={<UserOutlined />} />
                      )
                    }
                    title={item.full_name}
                    description={item.username}
                  />
                  <Row style={{ margin: 8 }}>
                    {acceptedFriends.find(
                      (acceptedFriend) => acceptedFriend.id === item.id
                    ) ||
                    requestedFriends.find(
                      (requestedFriend) => requestedFriend.id === item.id
                    ) ? (
                      <Tooltip
                        placement="topRight"
                        title="You can not send duplicate friend requests"
                      >
                        <Button type="text" disabled icon={<SendOutlined />} />
                      </Tooltip>
                    ) : (
                      <Button
                        type="text"
                        icon={<SendOutlined />}
                        onClick={() => {
                          mixpanel.track("Friend - search for friend", {
                            status: "success",
                            send_request: true,
                          });
                          // Pass user item
                          selectItemOnClick(item);
                          showSendRequestModal(true);
                        }}
                      />
                    )}
                  </Row>
                </Skeleton>
              </List.Item>
            )}
          />
        )}
      </Row>
      <Title level={5}>Suggested friends</Title>
      <Text>These users are in the same country as you - say hello!</Text>
      <Row style={{ marginBottom: 40 }}>
        {suggestedFriends && (
          <List
            loading={isLoading}
            itemLayout="horizontal"
            size="large"
            style={{ marginBottom: 24, width: "100%" }}
            dataSource={suggestedFriends}
            locale={{
              emptyText: (
                <Empty description="No users found" style={{ marginTop: 16 }} />
              ),
            }}
            renderItem={(item) => (
              <List.Item style={{ marginLeft: 16 }}>
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={
                      item.profile_image ? (
                        <Avatar src={item.profile_image} size="large" />
                      ) : (
                        <Avatar size="large" icon={<UserOutlined />} />
                      )
                    }
                    title={item.full_name}
                    description={item.username}
                  />
                  <Row style={{ margin: 8 }}>
                    {acceptedFriends.find(
                      (acceptedFriend) => acceptedFriend.id === item.id
                    ) ||
                    requestedFriends.find(
                      (requestedFriend) => requestedFriend.id === item.id
                    ) ? (
                      <Tooltip
                        placement="topRight"
                        title="You can not send duplicate friend requests"
                      >
                        <Button type="text" disabled icon={<SendOutlined />} />
                      </Tooltip>
                    ) : (
                      <Button
                        type="text"
                        icon={<SendOutlined />}
                        onClick={() => {
                          mixpanel.track("Friend - add suggested friend", {
                            id: item.id,
                          });
                          // Pass user item
                          selectItemOnClick(item);
                          showSendRequestModal(true);
                        }}
                      />
                    )}
                  </Row>
                </Skeleton>
              </List.Item>
            )}
          />
        )}
      </Row>
    </>
  );
};

export default AddFriendsTab;
