/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import {
  Layout,
  Row,
  Affix,
  Button,
  message,
  Image,
  Modal,
  Typography,
  // notification
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useNavigate, Navigate } from "react-router-dom";
import {
  TeamOutlined,
  EnvironmentOutlined,
  SettingOutlined,
} from "@ant-design/icons";
// TODO george fix notifications
// import { fetchToken, onMessageListener } from "../firebase";
// import { isSupported } from "firebase/messaging";
import routes from "../routes";
import logo from "../assets/images/wheresgeorgelogo.svg";

import share from "../assets/images/share-ios.svg";

import userApi from "../services/userApi";
import "./style.css";

const { Header, Content } = Layout;

const { Text } = Typography;

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("token"));
  // TODO george fix notifications
  // const notificationToken = !!localStorage.getItem("notification-token");
  // const [notifications, setNotifications] = useState(null);
  const [user, setUser] = useState(null);
  const [installModalVisibile, setInstallModalVisibile] = useState(false);
  const [appDownloaded, setAppDownloaded] = useState(false);
  const [installPrompt, setInstallPrompt] = useState(null);

  const [iosDevice, setIosDevice] = useState(false);

  const isIosDevice = () => {
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
      /iphone|ipad/.test(window.navigator.userAgent.toLowerCase())
    ) {
      setIosDevice(true);
    }
  };

  const addInstallListener = () => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setInstallPrompt(e);
    });
  };

  const checkIfAppDownloaded = async () => {
    if (!("serviceWorker" in window.navigator)) {
      setAppDownloaded(true);
    }
    if ("getInstalledRelatedApps" in window.navigator) {
      let relatedApps = await window.navigator.getInstalledRelatedApps();
      if (relatedApps.length > 0) {
        setAppDownloaded(true);
      }
    }
    const isInStandaloneMode =
      "standalone" in window.navigator && window.navigator.standalone;
    // Checks if should display install popup notification:
    if (isInStandaloneMode) {
      setAppDownloaded(true);
    }
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setAppDownloaded(true);
    }
    window.addEventListener("appinstalled", (e) => {
      e.preventDefault();
      setAppDownloaded(true);
    });

    window.addEventListener("DOMContentLoaded", () => {
      if (
        navigator.standalone ||
        window.matchMedia("(display-mode: standalone)").matches ||
        window.matchMedia("(display-mode: fullscreen)").matches ||
        window.matchMedia("(display-mode: minimal-ui)").matches
      ) {
        setAppDownloaded(true);
      }
    });
    // TODO george fix download button for desktop
    // if (!installPrompt) {
    //   setAppDownloaded(true);
    // }
  };

  useEffect(() => {
    addInstallListener();
    checkIfAppDownloaded();
    isIosDevice();
  }, []);

  useEffect(() => {
    fetchUser();
  }, []);

  // TODO george fix notifications
  // useEffect(() => {
  //   if (
  //     loggedIn &&
  //     typeof Notification !== "undefined" &&
  //     user &&
  //     !!isSupported()
  //   ) {
  //     Notification.requestPermission(function (permission) {
  //       if (permission === "granted" && !notificationToken) {
  //         fetchToken(user);
  //       }
  //     });
  //   }
  // }, [notificationToken, loggedIn, user]);

  // useEffect(() => {
  //   if (typeof Notification !== "undefined") {
  //     Notification.requestPermission(function (permission) {
  //       if (permission === "denied" || "default") {
  //         localStorage.removeItem("notification-token");
  //       }
  //     });
  //   }
  // }, []);

  const fetchUser = async () => {
    try {
      const response = await userApi.fetchCurrentUser();
      setUser(response);
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: response.id,
          username: response.username,
          email: response.email,
        });
      });
      mixpanel.register({
        id: response.id,
        username: response.username,
        email: response.email,
      });
    } catch (error) {
      message.error(`Fetching user failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
    }
  };

  // TODO george fix notifications
  // const openNotification = (payload) => {
  //   notification.open({
  //     message: payload.notification.title,
  //     description: payload.notification.body,
  //     onClick: () => {
  //       navigate("/friends#tab=3");
  //     },
  //   });
  // };

  // useEffect(() => {
  //   if (notifications) {
  //     openNotification(notifications);
  //   }
  // }, [notifications]);

  // onMessageListener()
  //   .then((payload) => {
  //     setNotifications(payload);
  //   })
  //   .catch((err) => {
  //     Sentry.captureException(err);
  //     console.log("failed: ", err);
  //   });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!localStorage.getItem("token")) {
      setLoggedIn(false);
      return;
    }
  }, []);

  const handleInstallClick = () => {
    installPrompt.prompt();
  };

  return (
    <>
      {!loggedIn && <Navigate to={routes.login} replace={true} />}
      <Layout
        style={{ backgroundColor: "white", height: "100%", width: "100%" }}
      >
        <Header
          style={{
            position: "fixed",
            height: 50,
            width: "100%",
            zIndex: 3,
            paddingLeft: 18,
            paddingRight: 18,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // TODO george match to future destination lines
            background: "transparent",
          }}
        >
          <Image
            width={240}
            src={logo}
            preview={false}
            style={{ margin: "8px 8px 20px 0px" }}
          />
          {!appDownloaded && (
            <Button
              style={{ marginTop: 10 }}
              // TODO george fix on desktop
              onClick={() =>
                iosDevice ? setInstallModalVisibile(true) : handleInstallClick()
              }
              shape="circle"
              icon={<DownloadOutlined />}
              size="medium"
            />
          )}
        </Header>

        {/* Page content */}
        <Content
          style={{
            width: "100%",
            minHeight: "calc(100vh - 90px)",
          }}
        >
          {children}
        </Content>
        <Row type="flex" justify="space-around">
          <Affix offsetBottom={30}>
            <Button
              shape="circle"
              icon={<EnvironmentOutlined />}
              onClick={() => navigate(routes.map)}
              size="large"
              style={{
                zIndex: 3,
                margin: 16,
              }}
            />
          </Affix>
          <Affix offsetBottom={30}>
            <Button
              shape="circle"
              icon={<TeamOutlined />}
              onClick={() => navigate(routes.friends)}
              size="large"
              style={{
                zIndex: 3,
                margin: 16,
              }}
            />
          </Affix>
          <Affix offsetBottom={30}>
            <Button
              shape="circle"
              icon={<SettingOutlined />}
              onClick={() => navigate(routes.you)}
              size="large"
              style={{
                zIndex: 3,
                margin: 16,
              }}
            />
          </Affix>
        </Row>
        <Modal
          title={"Install Whereisgeorge app"}
          visible={installModalVisibile}
          onOk={() => setInstallModalVisibile(false)}
          onCancel={() => setInstallModalVisibile(false)}
          okText={"Okay"}
          centered={true}
          className="modal"
        >
          <Text style={{ marginBottom: 10, fontSize: 16 }}>
            To install this app using Safari:
          </Text>
          <Row type="flex" justify="center">
            <Text>{"- tap the share button:"}</Text>
            <Image style={{ marginLeft: 5 }} width="20px" src={share} />
          </Row>
          <Row>
            <Text>{`- then tap 'Add to Homescreen'`}</Text>
          </Row>
          <Row style={{ marginTop: 12 }}>
            <Text>
              Note: please use Safari and follow the steps above, Apple will not
              support iOS Chrome app download until 2023
            </Text>
          </Row>
        </Modal>
      </Layout>
    </>
  );
};
export default AuthLayout;
