/* eslint-disable no-param-reassign */
import axios from "axios";

const api = axios.create({});

// Whenever a request is made, we attach our token in header.
api.interceptors.request.use((config) => {
  let token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Token ${token}`;
    // TODO george check if cors works
    // config.headers["Access-Control-Allow-Origin"] = "*";
  }
  return config;
});

// Whenever a response is received
api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response.data;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    // TODO george expand for different error messages
    if (error?.response?.data["non_field_errors"]) {
      return Promise.reject(error.response.data["non_field_errors"][0]);
    } else {
      return Promise.reject(error.response.statusText);
    }
  }
);

export default {
  api,
};
