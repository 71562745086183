import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { fetchToken } from "../../firebase";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import { Row, message, Tabs, Typography, notification } from "antd";
import {
  TeamOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  InfoCircleTwoTone,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { clone } from "lodash";
import userApi from "../../services/userApi";
import friendsApi from "../../services/friendsApi";

import FriendsTab from "./FriendsTab";
import AddFriendsTab from "./AddFriendsTab";
import FriendRequestsTab from "./FriendRequestsTab";
import OverlapsTab from "./OverlapsTab";
import ConfirmationModal from "../../components/ConfirmationModal";
import { friendStatus } from "../../constants";

const { TabPane } = Tabs;
const { Text } = Typography;

const TEXT_INPUT_DELAY = 600;

const FriendPage = () => {
  const location = useLocation();

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [acceptedFriends, setAcceptedFriends] = useState([]);
  const [requestedFriends, setRequestedFriends] = useState([]);
  const [blockedFriends, setBlockedFriends] = useState([]);
  const [overlapFriends, setOverlapFriends] = useState([]);
  const [suggestedFriends, setSuggestedFriends] = useState([]);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showSendRequestModal, setShowSendRequestModal] = useState(false);
  const [friendRequestMessage, setFriendRequestMessage] = useState(null);
  const [selectedItem, setSelectedItem] = useState(false);
  const [search, setSearch] = useState(null);
  const [searchResponse, setSearchResponse] = useState(null);
  // TODO george fix notifications
  // const [enableNotification, setEnableNotification] = useState("");
  const [activeKey, setActiveKey] = React.useState("1");

  const onKeyChange = (key) => {
    mixpanel.track("Friend - change tab", {
      new_tab: key,
    });
    setActiveKey(key);
  };

  useEffect(() => {
    if (location.hash !== "") {
      setActiveKey(location.hash.split("=").pop() + "");
    }
    if (location.search !== "") {
      setActiveKey("1");
      fetchFriendFromAddFriendCode();
    }
  }, [location]);

  useEffect(() => {
    mixpanel.track("Friends - page view");
    // TODO george add info to redux upon sign in / initial load
    fetchUser();
    fetchFriends();
    setIsLoading(false);
  }, []);

  // TODO george fix notifications
  // useEffect(() => {
  //   if (typeof Notification !== "undefined") {
  //     Notification.requestPermission(function (permission) {
  //       setEnableNotification(permission);
  //     });
  //   }
  // }, []);

  const fetchFriendFromAddFriendCode = async () => {
    try {
      setIsLoading(true);
      const addFriendCode = location.search.split("=").pop() + "";
      const response = await userApi.findFriendFromCode(addFriendCode);
      setSelectedItem(response);
      setShowSendRequestModal(true);
    } catch (error) {
      message.error(`Add friend code failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
      setIsLoading(false);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await userApi.fetchCurrentUser();
      setUser(response);
    } catch (error) {
      message.error(`Fetching user failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
      setIsLoading(false);
    }
  };

  const fetchFriends = async () => {
    try {
      const response = await friendsApi.fetchFriends();
      const [acceptedArray, requestedArray, blockedArray] =
        sortFriends(response);
      setAcceptedFriends(acceptedArray);
      setRequestedFriends(requestedArray);
      setBlockedFriends(blockedArray);
      const overlapResponse = await friendsApi.fetchOverlaps();
      setOverlapFriends(overlapResponse);
      const suggestedResponse = await userApi.fetchSuggested();
      setSuggestedFriends(suggestedResponse);
    } catch (error) {
      message.error(
        `Fetching friends failed: ${error || "Connection refused"}`
      );
      Sentry.captureException(error);
      setIsLoading(false);
    }
  };

  const sendFriendRequest = async () => {
    setIsLoading(true);
    mixpanel.track("Friend - send request", {
      friend_id: selectedItem.id,
      message: friendRequestMessage,
    });
    try {
      await friendsApi.request({
        friend_id: selectedItem.id,
        message: friendRequestMessage,
      });
      message.success(`Friend request sent to ${selectedItem.username}`);
    } catch (error) {
      message.error(`Friend request failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const acceptFriendRequest = async () => {
    setIsLoading(true);
    mixpanel.track("Friend - accept request", {
      friend_id: selectedItem.id,
    });
    try {
      await friendsApi.accept(selectedItem.id);
      message.success(`You are now friends with ${selectedItem.username}`);
      fetchFriends();
    } catch (error) {
      message.error(`Accept friend failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const blockUser = async () => {
    setIsLoading(true);
    mixpanel.track("Friend - block request", {
      friend_id: selectedItem.id,
    });
    try {
      await friendsApi.block(selectedItem.id);
      message.success(`${selectedItem.username} has been blocked`);
      fetchFriends();
    } catch (error) {
      message.error(`Block user failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortFriends = (friends) => {
    const acceptedArray = [];
    const requestedArray = [];
    const blockedArray = [];
    friends.map((item) => {
      if (item.status === friendStatus.ACCEPTED) {
        return acceptedArray.push(item.friend);
      }
      if (item.status === friendStatus.REQUESTED) {
        // Add message to friend item for display in requests tab
        item.friend["message"] = item.message;
        item.friend["is_sender"] = item.is_sender;
        return requestedArray.push(item.friend);
      }
      if (item.status === friendStatus.BLOCKED) {
        // This array is only used as a check currently
        return blockedArray.push(item.friend.id);
      }
      return item;
    });
    return [acceptedArray, requestedArray, blockedArray];
  };

  const onSearchUpdated = async (value) => {
    setIsLoading(true);
    setSearch(value?.length === 0 ? null : value);
    if (value?.length === 0) {
      setSearchResponse(null);
    }

    if (value) {
      try {
        const response = await userApi.findFriend(value.toLowerCase());
        setSearchResponse(response);
      } catch (error) {
        message.error(`Finding user failed: ${error || "Connection refused"}`);
        Sentry.captureException(error);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  // TODO george fix notifications
  // const openNotificationWithIcon = (type) => {
  //   if (enableNotification === "denied") {
  //     notification[type]({
  //       message: "Activate notifications",
  //       description:
  //         "In order to turn push notifications on, please go to your respective browser settings and allow the app to receive notifications.",
  //     });
  //   }
  //   if (enableNotification === "default") {
  //     Notification.requestPermission(function (permission) {
  //       if (permission === "granted") {
  //         fetchToken();
  //       }
  //     });
  //   }
  // };

  return (
    <div style={{ marginTop: 50 }}>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeKey}
        style={{ margin: 16 }}
        onChange={onKeyChange}
      >
        <TabPane
          tab={
            <span>
              <UserAddOutlined style={{ fontSize: 24 }} />
              {/* Add friends */}
            </span>
          }
          key="1"
        >
          <AddFriendsTab
            isLoading={isLoading}
            acceptedFriends={acceptedFriends}
            requestedFriends={requestedFriends}
            suggestedFriends={suggestedFriends}
            setSelectedItem={setSelectedItem}
            setShowSendRequestModal={setShowSendRequestModal}
            onSearchUpdated={onSearchUpdated}
            searchResponse={searchResponse}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <UsergroupAddOutlined style={{ fontSize: 24 }} />
              {/* Add friends */}
            </span>
          }
          key="2"
        >
          <FriendRequestsTab
            isLoading={isLoading}
            // Remove the recently sent friend requests
            requestedFriends={requestedFriends.filter(
              (item) => !item.is_sender
            )}
            setSelectedItem={setSelectedItem}
            setShowBlockModal={setShowBlockModal}
            setShowAcceptModal={setShowAcceptModal}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <UserSwitchOutlined style={{ fontSize: 24 }} />
            </span>
          }
          key="3"
        >
          <OverlapsTab
            overlapFriends={overlapFriends}
            isLoading={isLoading}
            // TODO george fix notifications
            // enableNotification={enableNotification}
            // openNotificationWithIcon={openNotificationWithIcon}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <TeamOutlined style={{ fontSize: 24 }} />
              {/* Friends */}
            </span>
          }
          key="4"
        >
          <FriendsTab
            acceptedFriends={acceptedFriends}
            isLoading={isLoading}
            setSelectedItem={setSelectedItem}
            setShowBlockModal={setShowBlockModal}
            blockedFriends={blockedFriends}
            setShowSendRequestModal={setShowSendRequestModal}
            requestedFriends={requestedFriends}
          />
        </TabPane>
      </Tabs>
      {showSendRequestModal && (
        <ConfirmationModal
          title={
            <>
              <InfoCircleTwoTone />
              <Text style={{ marginLeft: 8 }}>Send friend request</Text>
            </>
          }
          description={`Are you sure you want to send a friend request to ${selectedItem.full_name}?`}
          loading={isLoading}
          onOk={() => {
            sendFriendRequest();
            const updatedRequestedFriends = clone(requestedFriends);
            selectedItem.is_sender = true;
            updatedRequestedFriends.push(selectedItem);
            setRequestedFriends(updatedRequestedFriends);
            setShowSendRequestModal(false);
          }}
          onCancel={() => {
            setShowSendRequestModal(false);
          }}
          visible={showSendRequestModal}
          textInput
          textOnChange={debounce(
            (text) => setFriendRequestMessage(text.currentTarget.value),
            TEXT_INPUT_DELAY
          )}
        />
      )}
      {showAcceptModal && (
        <ConfirmationModal
          title={
            <>
              <PlusCircleTwoTone />
              <Text style={{ marginLeft: 8 }}>Accept friend request</Text>
            </>
          }
          description={
            <>
              <Row>
                <Text>
                  {`Are you sure you want to accept a friend request from ${selectedItem.full_name}?`}
                </Text>
              </Row>
              <Row>
                <Text>
                  They will be added to your map, and you will be added to
                  theirs.
                </Text>
              </Row>
            </>
          }
          loading={isLoading}
          onOk={() => {
            acceptFriendRequest();
            setShowAcceptModal(false);
          }}
          onCancel={() => {
            setShowAcceptModal(false);
          }}
          visible={showAcceptModal}
        />
      )}
      {showBlockModal && (
        <ConfirmationModal
          title={
            <>
              <MinusCircleTwoTone />
              <Text style={{ marginLeft: 8 }}>Block user</Text>
            </>
          }
          description={
            <>
              <Row>
                <Text>
                  {`Are you sure you want to remove ${selectedItem.full_name} as a friend?`}
                </Text>
              </Row>
              <Row>
                <Text>
                  They will be removed from your map, and you will be removed
                  from theirs.
                </Text>
              </Row>
              <Row>
                <Text>
                  They will not appear in your searches, and you will not appear
                  in theirs.
                </Text>
              </Row>
              <Row>
                <Text>We will not notify the user.</Text>
              </Row>
              <Row>
                <Text strong>Beware: this decision is final.</Text>
              </Row>
            </>
          }
          loading={isLoading}
          onOk={() => {
            blockUser();
            setShowBlockModal(false);
          }}
          onCancel={() => {
            setShowBlockModal(false);
          }}
          visible={showBlockModal}
        />
      )}
    </div>
  );
};

export default FriendPage;
