import base from "./base";
import { REGISTER_ENDPOINT, IMAGE_UPLOAD_ENDPOINT } from "../config";

const registerUser = async (user, image, destination) => {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("user", user);
  formData.append("destination", destination);
  const response = await base.api.post(
    `${REGISTER_ENDPOINT}/create-user/`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response;
};

const uploadProfileImage = async (file, userId) => {
  const formData = new FormData();
  formData.append("files", file.originFileObj);
  formData.append("ref", "plugin::users-permissions.user");
  formData.append("refId", userId);
  formData.append("field", "profileImage");
  const response = await base.api.post(`${IMAGE_UPLOAD_ENDPOINT}/`, formData);
  return response;
};

export default {
  registerUser,
  uploadProfileImage,
};
