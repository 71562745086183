import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, message } from "antd";

import loginApi from "../../services/loginApi";
import routes from "../../routes";

const LoginPage = () => {
  const [isLoading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") ? true : false
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const token = localStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
    }
  }, [loggedIn]);

  const onFinish = async (values) => {
    setLoading(true);
    const { username, password } = values;
    try {
      const response = await loginApi.login(username.toLowerCase(), password);
      const { token } = response;
      localStorage.setItem("token", token);
      setLoggedIn(true);
      setLoading(false);
    } catch (error) {
      message.error(`Login failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
      setLoading(false);
    }
  };

  return (
    <>
      {loggedIn && <Navigate to={routes.map} replace={true} />}
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        style={{
          background: "rgba(0,0,0,.4)",
          padding: 12,
          borderRadius: 12,
          marginBottom: 0,
          paddingBottom: 0,
        }}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your email",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={isLoading}
            >
              Log in
            </Button>
          </Row>
          <Row>
            <Link style={{ color: "white" }} to={routes.register}>
              or register
            </Link>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginPage;
