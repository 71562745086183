import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import { Button, Form, Input, Row, Select, Upload, message } from "antd";
import registerApi from "../../services/registerApi";
import locationsApi from "../../services/locationsApi";
import friendsApi from "../../services/friendsApi";
import urlUtils from "../../utils/urlUtils";
import locationUtils from "../../utils/locationUtils";
import routes from "../../routes";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};

const RegisterPage = () => {
  const [isLoading, setLoading] = useState(false);
  const [countries, setCountries] = useState(null);
  const [registationCode, setRegistationCode] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    mixpanel.track("Register - page view");
    fetchCountries();
    updateStateFromParams();
  }, []);

  const fetchCountries = async () => {
    setLoading(true);
    try {
      const response = await locationsApi.fetchCountries();
      setCountries(response);
      setLoading(false);
    } catch (error) {
      message.error(`Registration failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
      setLoading(false);
    }
  };

  const updateStateFromParams = () => {
    const { registration_code } = urlUtils.retrieveParamsFromUrl(
      window.location.search
    );
    setRegistationCode(registration_code);
    if (registationCode) {
      mixpanel.track("Register - redirect using registration code", {
        unique_registration_code: registationCode,
      });
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const { username, email, password, fullName, profileImage, location } =
      values;
    const profileImageFile = profileImage[0].originFileObj;
    const user = JSON.stringify({
      username: username.toLowerCase(),
      email: email.toLowerCase(),
      password: password,
      full_name: fullName,
    });
    const locationObject = countries.find((item) => item.id === location);
    const locationObjectUpdated = JSON.stringify(
      locationUtils.randomiseLocationFromObject(locationObject)
    );
    try {
      const response = await registerApi.registerUser(
        user,
        profileImageFile,
        locationObjectUpdated
      );
      if (registationCode) {
        await friendsApi.request({
          user_id: response.user.id,
          friend_registration_code: registationCode,
        });
      }

      // TODO george add to redux
      const { token } = response;
      localStorage.setItem("token", token);
      mixpanel.track("Register - new user registered", {
        user_id: response.user.id,
      });
      setLoading(false);
      navigate(routes.map);
    } catch (error) {
      // TODO george add more explanatory error messages
      message.error(`Registration failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: 8 }}>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        style={{
          background: "rgba(0,0,0,.4)",
          padding: 12,
          borderRadius: 12,
        }}
        requiredMark="optional"
      >
        <Form.Item
          className="white-form"
          name="email"
          label="Email"
          tooltip="This will only be used for login and password reset. We won't send you any nonsense."
          rules={[
            {
              type: "email",
              message: "The input is not a valid email.",
            },
            {
              required: true,
              message: "Please input your email.",
            },
          ]}
        >
          <Input
            style={{ fontColor: "white" }}
            placeholder="george@whereisgeorge.com"
          />
        </Form.Item>

        <Form.Item
          className="white-form"
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password.",
            },
            {
              min: 8,
              message: "Please input a minimum of 8 characters",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          className="white-form"
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("The two passwords that you entered do not match.")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          className="white-form"
          name="fullName"
          label="Full name"
          tooltip="This is how users will recognise you outside of your username."
          rules={[
            {
              required: true,
              message: "Please input your name",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="George Ballard" />
        </Form.Item>

        <Form.Item
          className="white-form"
          name="username"
          label="Username"
          placeholder="beyoncebooty"
          tooltip="This is how other users will find you. This will be forced to all lower case."
          rules={[
            {
              required: true,
              message: "Please input your username",
            },
            // TODO george debounce and check if username exists, same for email
            // TODO add regex for whitespace
          ]}
        >
          <Input placeholder="beyoncebooty" />
        </Form.Item>
        <Form.Item
          className="white-form"
          name="location"
          label="Current location"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Search for a country"
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {countries &&
              countries.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="white-form"
          name="profileImage"
          label="Upload profile picture"
          tooltip="Please use a recognisable image, preferably of your face. There is currently no way to update your image after you register."
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Image is required",
            },
          ]}
        >
          <Upload
            listType="picture"
            action={(data) =>
              form.setFieldsValue("profileImage", data[0].originFileObj)
            }
            beforeUpload={() => false}
            accept=".jpg,.jpeg,.png"
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Row>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Register
            </Button>
          </Row>
          <Row style={{ paddingBottom: 12 }}>
            <Link style={{ color: "white" }} to={routes.login}>
              or login
            </Link>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterPage;
