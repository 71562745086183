import base from "./base";
import { FRIENDS_ENDPOINT } from "../config";

const fetchFriendDestinations = async () => {
  const response = await base.api.get(
    `${FRIENDS_ENDPOINT}/fetch-friend-destinations/`
  );
  return response;
};

const fetchOverlaps = async () => {
  const response = await base.api.get(`${FRIENDS_ENDPOINT}/fetch-overlaps/`);
  return response;
};

const fetchFriends = async (params) => {
  const response = await base.api.get(`${FRIENDS_ENDPOINT}/`, { params });
  return response;
};

const request = async (params) => {
  const response = await base.api.post(`${FRIENDS_ENDPOINT}/request/`, params);
  return response;
};

const accept = async (friendId) => {
  const response = await base.api.post(`${FRIENDS_ENDPOINT}/accept/`, {
    friend_id: friendId,
  });
  return response;
};

const block = async (friendId) => {
  const response = await base.api.post(`${FRIENDS_ENDPOINT}/block/`, {
    friend_id: friendId,
  });
  return response;
};

export default {
  fetchFriends,
  fetchFriendDestinations,
  fetchOverlaps,
  request,
  accept,
  block,
};
