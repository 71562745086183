import React from "react";
import * as Sentry from "@sentry/browser";
// TODO george add mixpanel events
import mixpanel from "mixpanel-browser";
import { Row, Col, Avatar, List, Empty, Typography } from "antd";
import {
  UserOutlined,
  GoogleOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

const { Title, Text, Paragraph } = Typography;

const OverlapsTab = ({
  overlapFriends,
  isLoading,
  // TODO george fix notifications
  // enableNotification,
  // openNotificationWithIcon,
}) => {
  // TODO george fix notifications
  // const handleOpenNotificationWithIcon = (value) => {
  //   openNotificationWithIcon(value);
  // };

  return (
    <>
      <Row type="flex" justify="center">
        {/* TODO george fix notifications */}
        {/* {enableNotification !== "granted" && (
          <Alert
            message="Turn notifications on?"
            description="Turn on notifications to get notified when you cross paths with your friends!"
            type="info"
            action={
              <Space direction="vertical">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => handleOpenNotificationWithIcon("info")}
                >
                  Activate
                </Button>
              </Space>
            }
          />
        )} */}
      </Row>
      <Title level={5}>Overlaps</Title>
      <Text>The following friends are in the same country as you:</Text>
      <Row style={{ marginBottom: 40 }}>
        <List
          loading={isLoading}
          itemLayout="horizontal"
          size="large"
          style={{ marginBottom: 24, width: "100%" }}
          dataSource={overlapFriends}
          locale={{
            emptyText: (
              <Empty
                description="You should probably make some more friends."
                style={{ mmarginTop: 16 }}
              />
            ),
          }}
          renderItem={({ friend }) => (
            <List.Item style={{ marginLeft: 16 }}>
              <Row type="flex" align="middle">
                <Col>
                  {friend?.profile_image ? (
                    <Avatar
                      src={friend.profile_image}
                      size="large"
                      style={{ marginBottom: 8 }}
                    />
                  ) : (
                    <Avatar
                      size="large"
                      icon={<UserOutlined />}
                      style={{ marginBottom: 8 }}
                    />
                  )}
                </Col>
                <Col>
                  <Row>
                    <Paragraph
                      ellipsis={{ rows: 1 }}
                      style={{
                        marginBottom: 0,
                        marginLeft: 8,
                        marginRight: 8,
                        maxWidth: 120,
                      }}
                    >
                      {friend.full_name}
                    </Paragraph>
                  </Row>
                  <Row>
                    <Paragraph
                      ellipsis={{ rows: 1 }}
                      style={{
                        marginBottom: 0,
                        marginLeft: 8,
                        marginRight: 8,
                        maxWidth: 110,
                      }}
                    >
                      {friend.username}
                    </Paragraph>
                  </Row>
                  {friend?.contact_email && (
                    <Row
                      type="flex"
                      align="middle"
                      style={{
                        marginLeft: 6,
                      }}
                    >
                      <GoogleOutlined />
                      {": "}
                      <Paragraph
                        copyable
                        ellipsis={{ rows: 1 }}
                        style={{
                          marginBottom: 0,
                          marginLeft: 4,
                          maxWidth: 160,
                        }}
                      >
                        {friend.contact_email}
                      </Paragraph>
                    </Row>
                  )}
                  {friend?.contact_instagram && (
                    <Row
                      type="flex"
                      align="middle"
                      style={{
                        marginLeft: 6,
                      }}
                    >
                      <InstagramOutlined />
                      {": "}
                      <Paragraph
                        copyable
                        ellipsis={{ rows: 1 }}
                        style={{
                          marginBottom: 0,
                          marginLeft: 4,
                          maxWidth: 160,
                        }}
                      >
                        {friend.contact_instagram}
                      </Paragraph>
                    </Row>
                  )}
                  {friend?.contact_mobile && (
                    <Row
                      type="flex"
                      align="middle"
                      style={{
                        marginLeft: 6,
                      }}
                    >
                      <WhatsAppOutlined />
                      {": "}
                      <Paragraph
                        copyable
                        style={{ marginBottom: 0, marginLeft: 4 }}
                      >
                        {friend.contact_mobile}
                      </Paragraph>
                    </Row>
                  )}
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Row>
    </>
  );
};

export default OverlapsTab;
