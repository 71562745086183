import React, { useState } from "react";
import {
  Row,
  Col,
  Avatar,
  message,
  Tooltip,
  Skeleton,
  List,
  Empty,
  Typography,
  Button,
  Modal,
} from "antd";
import {
  SendOutlined,
  UserOutlined,
  GoogleOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import { clone } from "lodash";
import friendsApi from "../services/friendsApi";

const { Text, Paragraph } = Typography;

const RecommendationModal = ({
  userList,
  countryName,
  friends,
  loading,
  visible,
  onOk,
  onCancel,
}) => {
  const [requestedFriends, setRequestedFriends] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const requestMessage = `"Hey, I'm in ${countryName} and the app told me to ask you for recommendations!"`;

  const sendFriendRequest = async (item) => {
    setIsLoading(true);
    mixpanel.track("Recommendations modal - send request", {
      friend_id: item.id,
      message: requestMessage,
    });
    try {
      await friendsApi.request({
        friend_id: item.id,
        message: requestMessage,
      });
      message.success(`Friend request sent to ${item.username}`);
    } catch (error) {
      message.error(`Friend request failed: ${error || "Connection refused"}`);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      title={`These users have also been to ${countryName}`}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={loading || isLoading}
      closable={false}
    >
      {friends ? (
        <>
          <Text>Ask your friends for recommendations:</Text>
          <List
            loading={loading || isLoading}
            itemLayout="horizontal"
            size="large"
            style={{ marginBottom: 24, width: "100%" }}
            dataSource={userList}
            locale={{
              emptyText: (
                <Empty
                  description="You should probably make some more friends."
                  style={{ mmarginTop: 16 }}
                />
              ),
            }}
            renderItem={(item) => (
              <List.Item style={{ marginLeft: 16 }}>
                <Row type="flex" align="middle">
                  <Col>
                    {item?.profile_image ? (
                      <Avatar
                        src={item.profile_image}
                        size="large"
                        style={{ marginBottom: 8 }}
                      />
                    ) : (
                      <Avatar
                        size="large"
                        icon={<UserOutlined />}
                        style={{ marginBottom: 8 }}
                      />
                    )}
                  </Col>
                  <Col>
                    <Row>
                      <Paragraph
                        ellipsis={{ rows: 1 }}
                        style={{
                          marginBottom: 0,
                          marginLeft: 8,
                          marginRight: 8,
                          maxWidth: 120,
                        }}
                      >
                        {item.full_name}
                      </Paragraph>
                    </Row>
                    <Row>
                      <Paragraph
                        ellipsis={{ rows: 1 }}
                        style={{
                          marginBottom: 0,
                          marginLeft: 8,
                          marginRight: 8,
                          maxWidth: 110,
                        }}
                      >
                        {item.username}
                      </Paragraph>
                    </Row>
                    {item?.contact_email && (
                      <Row
                        type="flex"
                        align="middle"
                        style={{
                          marginLeft: 6,
                        }}
                      >
                        <GoogleOutlined />
                        {": "}
                        <Paragraph
                          copyable
                          ellipsis={{ rows: 1 }}
                          style={{
                            marginBottom: 0,
                            marginLeft: 4,
                            maxWidth: 160,
                          }}
                        >
                          {item.contact_email}
                        </Paragraph>
                      </Row>
                    )}
                    {item?.contact_instagram && (
                      <Row
                        type="flex"
                        align="middle"
                        style={{
                          marginLeft: 6,
                        }}
                      >
                        <InstagramOutlined />
                        {": "}
                        <Paragraph
                          copyable
                          ellipsis={{ rows: 1 }}
                          style={{
                            marginBottom: 0,
                            marginLeft: 4,
                            maxWidth: 160,
                          }}
                        >
                          {item.contact_instagram}
                        </Paragraph>
                      </Row>
                    )}
                    {item?.contact_mobile && (
                      <Row
                        type="flex"
                        align="middle"
                        style={{
                          marginLeft: 6,
                        }}
                      >
                        <WhatsAppOutlined />
                        {": "}
                        <Paragraph
                          copyable
                          style={{ marginBottom: 0, marginLeft: 4 }}
                        >
                          {item.contact_mobile}
                        </Paragraph>
                      </Row>
                    )}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </>
      ) : (
        <>
          <Row>
            <Text>Add them and we'll send them the following message:</Text>
          </Row>
          <Row>
            <Text>{requestMessage}</Text>
          </Row>
          <List
            loading={loading || isLoading}
            itemLayout="horizontal"
            size="large"
            style={{ marginLeft: 16 }}
            dataSource={userList}
            locale={{
              emptyText: (
                <Empty
                  description="No available friends"
                  style={{ marginTop: 16 }}
                />
              ),
            }}
            renderItem={(item) => (
              <List.Item>
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={
                      item.profile_image ? (
                        <Avatar src={item.profile_image} size="large" />
                      ) : (
                        <Avatar size="large" icon={<UserOutlined />} />
                      )
                    }
                    title={item.full_name}
                    description={item.username}
                  />
                  <Row style={{ margin: 8 }}>
                    {requestedFriends.find(
                      (requestedFriend) => requestedFriend.id === item.id
                    ) ? (
                      <Tooltip
                        placement="topRight"
                        title="You can not send duplicate friend requests"
                      >
                        <Button type="text" disabled icon={<SendOutlined />} />
                      </Tooltip>
                    ) : (
                      <Button
                        type="text"
                        icon={<SendOutlined />}
                        onClick={() => {
                          // Pass user item
                          sendFriendRequest(item);
                          const updatedRequestedFriends =
                            clone(requestedFriends);
                          updatedRequestedFriends.push(item);
                          setRequestedFriends(updatedRequestedFriends);
                        }}
                      />
                    )}
                  </Row>
                </Skeleton>
              </List.Item>
            )}
          />
        </>
      )}
    </Modal>
  );
};

export default RecommendationModal;
