import { environment, getEnvironment } from "./utilities";

// Determine hostname and some other URLs which are different in different environments
let hostname;

const env = getEnvironment();

if (env === environment.PRODUCTION) {
  hostname = "https://seal-app-ux2gd.ondigitalocean.app";
} else {
  hostname = "http://localhost:8000";
}

export const BASE_ENDPOINT = hostname;

export const BASE_API_ENDPOINT = `${BASE_ENDPOINT}/api`;

export const USER_ENDPOINT = `${BASE_API_ENDPOINT}/user`;

export const FRIENDS_ENDPOINT = `${BASE_API_ENDPOINT}/friends`;

export const REGISTER_ENDPOINT = `${BASE_API_ENDPOINT}/register`;

export const IMAGE_UPLOAD_ENDPOINT = `${BASE_API_ENDPOINT}/upload`;

export const DESTINATIONS_ENDPOINT = `${BASE_API_ENDPOINT}/destinations`;

export const COUNTRIES_ENDPOINT = `${BASE_API_ENDPOINT}/countries`;

export const LOGIN_ENDPOINT = `${BASE_API_ENDPOINT}/login`;

// TODO george move to env variables
export const MAPBOX_API_TOKEN =
  "pk.eyJ1IjoiZ2VvcmdlYmFsbGFyZHNvZnR3YXJlIiwiYSI6ImNsNWRiMTR3djBwcTUzbW9ldGJ1emx4ankifQ.FeOSQ6TxMH9TnHodvsd4zQ";

export const MAPBOX_MAP_STYLE =
  "mapbox://styles/georgeballardsoftware/cl5dfdvsh00cy16p3uvsy41u2";

export const FIREBASE_API_KEY = "AIzaSyBxVJ7KVeAfxyAKqJW1PA_AOP7UXtktkxY";
export const FIREBASE_AUTH_DOMAIN = "maps-fe.firebaseapp.com";
export const FIREBASE_PROJECT_ID = "maps-fe";
export const FIREBASE_STORAGE_BUCKET = "maps-fe.appspot.com";
export const FIREBASE_MESSAGING_SENDER_ID = "368414034954";
export const FIREBASE_APP_ID = "1:368414034954:web:0d9c522ce763996d2723f3";
export const VAPID_KEY =
  "BCMSoyl1IqhuedR3C2Uapx9yl7VHtnHHOwMaKlDCgssQ2NeZgo7PPPmu78pDtfi4TUyhUPUswkj2-mFLg179AX0";
