import React from "react";
import * as Sentry from "@sentry/browser";
// TODO george add mixpanel events
import mixpanel from "mixpanel-browser";
import {
  Row,
  Avatar,
  Skeleton,
  List,
  Empty,
  Typography,
  Button,
  Alert,
} from "antd";
import {
  UserDeleteOutlined,
  UserOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

const { Text, Title } = Typography;

const FriendRequestsTab = ({
  isLoading,
  requestedFriends,
  setSelectedItem,
  setShowBlockModal,
  setShowAcceptModal,
}) => {
  const selectItemOnClick = (item) => {
    setSelectedItem(item);
  };

  const showBlockModal = (boolean) => {
    setShowBlockModal(boolean);
  };

  const showAcceptModal = (boolean) => {
    setShowAcceptModal(boolean);
  };

  return (
    <>
      <Title level={5}>Friend requests</Title>
      {requestedFriends?.length > 0 && (
        <Alert
          description="To appear on someone else's map, you must accept their friend request (and vice versa)"
          type="info"
          showIcon
        />
      )}
      <List
        loading={isLoading}
        itemLayout="horizontal"
        size="large"
        style={{ margin: 16 }}
        dataSource={requestedFriends}
        locale={{
          emptyText: (
            <Empty
              description="You should probably make some friends"
              style={{ marginTop: 16 }}
            />
          ),
        }}
        renderItem={(item) => (
          <List.Item>
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={
                  item.profile_image ? (
                    <Avatar src={item.profile_image} size="large" />
                  ) : (
                    <Avatar size="large" icon={<UserOutlined />} />
                  )
                }
                title={item.full_name}
                description={
                  <Text
                    ellipsis={{
                      tooltip: item.message,
                    }}
                    type="secondary"
                  >
                    {item.message || item.username}
                  </Text>
                }
              />
              <Row style={{ margin: 8 }}>
                <Button
                  shape="circle"
                  icon={<UserAddOutlined />}
                  onClick={() => {
                    // Pass friend item
                    selectItemOnClick(item);
                    showAcceptModal(true);
                  }}
                />
              </Row>
              <Row style={{ margin: 8 }}>
                <Button
                  shape="circle"
                  icon={<UserDeleteOutlined />}
                  onClick={() => {
                    // Pass friend item
                    selectItemOnClick(item);
                    showBlockModal(true);
                  }}
                />
              </Row>
            </Skeleton>
          </List.Item>
        )}
      />
    </>
  );
};

export default FriendRequestsTab;
