import { Modal, Typography, Input } from "antd";
import React from "react";
const { TextArea } = Input;

const { Text } = Typography;

const ConfirmationModal = ({
  visible,
  onOk,
  onCancel,
  loading,
  title,
  description,
  textInput = false,
  textOnChange,
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onOk}
      confirmLoading={loading}
      onCancel={onCancel}
    >
      <Text>{description}</Text>
      {textInput && (
        <TextArea
          placeholder="Add a message (optional)"
          allowClear
          showCount
          maxLength={100}
          onChange={textOnChange}
          style={{ marginTop: 8 }}
        />
      )}
    </Modal>
  );
};

export default ConfirmationModal;
